@import "~bootstrap/scss/bootstrap.scss";
@import "~bootstrap-icons/font/bootstrap-icons.css";

$d-primary-blue: #112e51;
$d-primary-red: #d61c31;
$d-heading-color: $gray-700;

a.d-no-decor {
  text-decoration: none !important;
}

.d-primary-red {
  color: $d-primary-red;

  &:hover {
    color: $d-primary-red;
  }
}

.d-primary-red-bg {
  color: white;
  background-color: $d-primary-red;

  a {
    color: white;
  }
}

.d-btn-primary-red {
  @include button-variant($d-primary-red, $d-primary-red);
}

#id-main-footer {
  color: white;
  background-color: $d-primary-blue;

  a,
  a:hover {
    color: white;
    text-decoration: none;
  }
}

#id-main-hero {
  color: white;
  background-image: url("../img/zasedacka4.jpg");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

#id-main-about {
  color: white;
  background-color: $d-primary-blue;

  button {
    color: white;
    background-color: $d-primary-red;
  }
}

@include media-breakpoint-up(md) {
  .dr-article-preview {
    .dr-article-preview-image {
      max-width: 250px;
    }
  }
}

/* **************************************************
 * Base page
 */

.drv-page-h {
  @extend .mt-4;
  @extend .mb-5;

  /* color: $d-heading-color; */
}

/* **************************************************
 * Base - dva sloupce
 */

.drv-2c-side {
  @include make-col;

  display: none;

  @include media-breakpoint-up(lg) {
    display: block;
  }

  @extend .border-start;

  h2 {
    @extend .mb-4;
    @extend .fs-5;
  }
}

/* **************************************************
 * Articles
 */

.drv-news-side {
  ul {
    list-style: none;
    padding: 0;

    @extend .small;

    li {
      @extend .pb-3;
      @extend .mb-3;
      @extend .border-bottom;
    }
  }
}

a.drv-page-link {
  text-decoration: none;
  color: $d-primary-red;
}

.drv-article-detail {
  @include media-breakpoint-up(md) {
    max-width: 640px;
  }

  h1,
  h2 {
    margin-bottom: $spacer * 1.5;
    color: $d-primary-red;
  }

  .drv-article-image {
    @extend .mb-5;
  }

  .drv-date {
    @extend .small;
    @extend .mb-3;
    @extend .fw-bold;
    @extend .text-muted;
  }
}

/* **************************************************
 * Profile
 */

.drv-profile-img {
  @include media-breakpoint-up(lg) {
    max-width: 480px;
  }

  @include media-breakpoint-up(xl) {
    max-width: 640px;
  }
}

/* **************************************************
 * GDPR
 */

/*
.drv-gdpr-content {
  h2,
  h3,
  h4 {
    color: $d-heading-color;
  }
}
*/

/* ************************************************** */
